/* eslint sort-keys: "error" */
/* eslint-disable no-console */
import { captureException } from '@sentry/react'

/**
 * @param {css styles} styles: string
 * @returns a closure
 */
function createConsoleMessage(styles, isError = false) {
  return (message) => {
    // send errors to sentry
    if (isError && process.env?.NODE_ENV !== 'local') {
      captureException(message)
    }
    if (process.env?.NODE_ENV !== 'production') {
      console.log(`%c ${typeof message === 'object' ? JSON.stringify(message, null, 2) : message} `, styles)
    }
  }
}

/**
 * Usage:
 * import { log } from '../functions/utils'
 * log.info('Test of log')
 */
export const log = {
  error: createConsoleMessage('background:red;color:white;', true),
  info: createConsoleMessage('background:blue;color:white;'),
  success: createConsoleMessage('background:green;color:white;'),
  warning: createConsoleMessage('background:orange;color:black;')
}
