import { createSlice } from '@reduxjs/toolkit'
// services
import { createSpaceQuestion, getSpaceQuestions, updateSpaceQuestion } from '../../services/space'
import { log } from '../../utils/logger'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {}
}

const slice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getQuestionsSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.data = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getQuestions(spaceId) {
  const { startLoading, hasError, getQuestionsSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getSpaceQuestions(spaceId)
      dispatch(getQuestionsSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function createQuestion(spaceID, questionValue) {
  const { hasError } = slice.actions
  let step = null
  return async (dispatch) => {
    try {
      const questionInfo = {
        question: questionValue.questionName,
        topic_id: questionValue.id
      }
      step = 'Sending question info to endpoint'
      await createSpaceQuestion(spaceID, questionInfo)
      dispatch(getQuestions(spaceID))
    } catch (error) {
      log.error({ step, questionValue, error })
      dispatch(hasError(error))
    }
  }
}

export function updateQuestion(spaceID, questionValue) {
  const { hasError } = slice.actions
  let step = null
  return async (dispatch) => {
    try {
      const questionInfo = {
        question: questionValue.questionName,
        topic_id: questionValue.id
      }
      const questionId = questionValue.quesId
      step = 'Sending question info to endpoint'
      await updateSpaceQuestion(spaceID, questionId, questionInfo)
      dispatch(getQuestions(spaceID))
    } catch (error) {
      log.error({ step, questionValue, error })
      dispatch(hasError(error))
    }
  }
}
