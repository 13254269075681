import PropTypes from 'prop-types'
import { createContext, useReducer, useEffect } from 'react'
// auth apis
import { useGoogleLogin, useGoogleLogout } from 'react-google-login'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import {
  googleOauthLogin,
  linkedInOauthLogin,
  resetPassword,
  ssoCallbackLogin,
  twineSignup,
  twineLogin
} from '../services/auth'
// redux
import { useDispatch } from '../redux/store'
import { getUser, resetData } from '../redux/slices/user'
// utils
import { getValue, saveValue, removeValue, KEYS } from '../utils/handleLocalStorage'
import { log } from '../utils/logger'
import { checkJWT } from '../utils/auth'

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

let isJWTFlow = false // Flag to identify if we are getting authentication from jwt in url

const linkedinRedirectUri = window.location.origin

const handlers = {
  INITIALIZE: (state) => ({ ...state, isInitialized: true }),
  LOGIN_SUCCESS: (state, action) => {
    const { user } = action.payload
    return {
      ...state,
      isAuthenticated: true,
      user
    }
  },
  LOGIN_FAIL: (state) => ({ ...state, isAuthenticated: false, user: null }),
  LOGOUT_SUCCESS: (state) => ({ ...state, isAuthenticated: false, user: null }),
  LOGOUT_FAIL: (state) => ({ ...state })
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state)

const AuthContext = createContext({})

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'INITIALIZE' })
    const loadUser = () => {
      const userId = getValue(KEYS.USER_ID)
      const token = getValue(KEYS.TOKEN)
      if (userId && token) {
        setTimeout(() => reduxDispatch(getUser(userId)))
        dispatch({
          type: 'LOGIN_SUCCESS',
          payload: {
            user: {
              id: userId
            }
          }
        })
      }
    }
    loadUser()
  }, [dispatch, reduxDispatch])

  /**
   * Stores tokens, userId and update user context and redux user object
   * @param {oauth response} response
   */
  const updateAuthenticatedUser = (response) => {
    const { user_id: userId, token, refresh_token: refreshToken } = response.response_objects
    saveValue(KEYS.TOKEN, token)
    saveValue(KEYS.USER_ID, userId)
    saveValue(KEYS.REFRESH_TOKEN, refreshToken)
    if (userId) {
      setTimeout(() => reduxDispatch(getUser(userId)))
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
          user: {
            id: userId
          }
        }
      })
    }
  }

  /**
   * Google custom hook for signin in
   */
  const { signIn: googleLogin } = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
    cookiePolicy: 'single_host_origin',
    onSuccess: (response) => {
      const login = async () => {
        const { tokenId } = response
        const payload = {
          id_token: tokenId
        }
        try {
          const response = await googleOauthLogin(payload)
          updateAuthenticatedUser(response)
        } catch (error) {
          log.error(response)
          dispatch({ type: 'LOGIN_FAIL' })
        }
      }
      login()
    },
    onFailure: (response) => {
      // if error === idpiframe_initialization_failed then we are in incognito mode
      // or there was an error loading the google login library so user cannot signin
      // But if user is authenticated through JWT passed in the url
      // we should NOT dispatch a LOGIN_FAIL action type
      if (response.error === 'idpiframe_initialization_failed' && isJWTFlow) {
        // user logged in with jwt and is in incognito mode (iframe cannot be loaded)
      } else {
        // other google signin error
        dispatch({ type: 'LOGIN_FAIL' })
      }
      log.error(response)
    },
    isSignedIn: true
  })

  /**
   * Google custom hook for signin out
   */
  const { signOut: googleLogout } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
    cookiePolicy: 'single_host_origin',
    onLogoutSuccess: () => {
      const logout = () => {
        reduxDispatch(resetData())
        removeValue(KEYS.TOKEN)
        removeValue(KEYS.USER_ID)
        dispatch({ type: 'LOGOUT_SUCCESS' })
      }
      logout()
    },
    onFailure: (response) => {
      // idpiframe_initialization_failed when in incognito mode
      // User is login out so we clean all user data
      log.error(response)
      reduxDispatch(resetData())
      removeValue(KEYS.TOKEN)
      removeValue(KEYS.USER_ID)
      dispatch({ type: 'LOGOUT_SUCCESS' })
    }
  })

  /**
   * LinkedIn custom hook for signin in
   */
  // http://localhost:3000 is not registered as authorized domain for redirect in Linkedin account
  // so we cannot test it locally
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENTID,
    redirectUri: `${linkedinRedirectUri}/linkedin`,
    scope: 'r_emailaddress,r_liteprofile',
    onSuccess: (code) => {
      const login = async () => {
        const payload = {
          id_token: code
        }
        try {
          const response = await linkedInOauthLogin(payload)
          updateAuthenticatedUser(response)
        } catch (error) {
          log.error(error)
          dispatch({ type: 'LOGIN_FAIL' })
        }
      }
      login()
    },
    onError: (error) => {
      log.error(error)
      dispatch({ type: 'LOGIN_FAIL' })
    }
  })

  /**
   * Twine signup
   * @param {email, firstName, lastName} payload
   * @returns if no error, user was added and authenticated
   */
  const signUp = async (payload) =>
    new Promise((resolve, reject) => {
      twineSignup(payload)
        .then((response) => {
          updateAuthenticatedUser(response)
          resolve()
        })
        .catch((error) => reject(error))
    })

  /**
   * Exchange SSO returned code and login the user
   * @param {string} code
   * @returns if no error, user was added and authenticated
   */
  const ssoLogin = async (payload) =>
    new Promise((resolve, reject) => {
      ssoCallbackLogin(payload)
        .then((response) => {
          updateAuthenticatedUser(response)
          resolve()
        })
        .catch((error) => reject(error))
    })

  /**
   * Sends email address to generate verification code
   * @param {email} payload
   * @returns Sends email with verification code
   */
  const login = (payload) => resetPassword(payload)

  /**
   * Checks for url jwt value received from appweb in url
   * and updates user auth values
   * @returns {string} Path received from appweb in url (i.e.: "/home", "/profile", etc.)
   */
  const authJWT = async () => {
    const values = await checkJWT()
    isJWTFlow = !!values.userId // jwt is valid
    const response = {
      response_objects: {
        user_id: values.userId,
        token: null,
        refresh_token: values.refreshToken
      }
    }
    updateAuthenticatedUser(response)
    return values?.path
  }

  /**
   * Send email and verification code to login
   * @param {email, password} payload
   * @returns If no error, user is authenticated
   */
  const verify = async (payload) =>
    new Promise((resolve, reject) => {
      twineLogin(payload)
        .then((response) => {
          updateAuthenticatedUser(response)
          resolve()
        })
        .catch((error) => reject(error))
    })

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signUp,
        login,
        verify,
        googleLogin,
        googleLogout,
        linkedInLogin,
        ssoLogin,
        authJWT
      }}
    >
      {state.isInitialized && children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node
}

export { AuthContext, AuthProvider }
