import { createSlice } from '@reduxjs/toolkit'
// services
import { getRoomAnalytics } from '../../services/rooms'
// utils
import { log } from '../../utils/logger'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {}
}

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getSpaceAnalyticsSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.data = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getAnalytics(roomId) {
  const { startLoading, hasError, getSpaceAnalyticsSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalytics(roomId)
      dispatch(getSpaceAnalyticsSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}
