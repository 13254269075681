import { Suspense, lazy } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
// LinkedIn
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
// layouts
import DashboardLayout from '../layouts/dashboard'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
// components
import LoadingScreen from '../components/LoadingScreen'
// guards
import GuestGuard from '../guards/GuestGuard'
import AuthGuard from '../guards/AuthGuard'
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()
  const isDashboard = pathname.includes('/dashboard')

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      )
    },
    {
      path: 'signup',
      element: (
        <GuestGuard>
          <Signup />
        </GuestGuard>
      )
    },
    {
      path: 'verify',
      element: (
        <GuestGuard>
          <VerifyCode />
        </GuestGuard>
      )
    },
    {
      path: 'claim',
      element: (
        <GuestGuard>
          <PLGRegister />
        </GuestGuard>
      )
    },
    {
      path: '/linkedin',
      element: <LinkedInCallback />
    },
    {
      path: '/login/sso/callback',
      element: <Login />
    },
    // User Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/home" replace /> },
        { path: 'home', element: <Home /> },
        { path: 'profile', element: <Profile /> },
        { path: 'profile/:id', element: <Profile /> },
        { path: 'profile/edit', element: <Settings /> },
        { path: 'connections', element: <Connections /> },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'messages', element: <Messages /> },
        { path: 'rooms', element: <Rooms /> },
        { path: 'rooms/create', element: <CreateRoom /> },
        { path: 'rooms/:id/edit', element: <CreateRoom /> },
        { path: 'insights/room/:id', element: <Insights /> },
        { path: 'insights', element: <Insights /> },
        { path: 'members', element: <Members /> },
        { path: 'partner/:id', element: <PartnerProfile /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ])
}

// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')))
const Register = Loadable(lazy(() => import('../pages/authentication/Register')))
const Signup = Loadable(lazy(() => import('../pages/authentication/Signup')))
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')))
const PLGRegister = Loadable(lazy(() => import('../pages/authentication/PLGRegister')))

// Dashboard
const Home = Loadable(lazy(() => import('../pages/Home')))
const Profile = Loadable(lazy(() => import('../pages/Profile')))
const Settings = Loadable(lazy(() => import('../pages/Settings')))
const Connections = Loadable(lazy(() => import('../pages/Connections')))
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')))
const Rooms = Loadable(lazy(() => import('../pages/Rooms')))
const Messages = Loadable(lazy(() => import('../pages/Messages')))
const CreateRoom = Loadable(lazy(() => import('../components/rooms/create-room/CreateRoom')))
const Insights = Loadable(lazy(() => import('../pages/Insights')))
const Members = Loadable(lazy(() => import('../pages/Members')))
const NotFound = Loadable(lazy(() => import('../pages/Page404')))
const PartnerProfile = Loadable(lazy(() => import('../pages/PartnerProfile')))
