// ----------------------------------------------------------------------
export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  signup: '/signup',
  verify: '/verify',
  claim: '/claim'
}

// ----------------------------------------------------------------------

export const PATH_SECURED = {
  root: '/',
  home: '/home',
  profile: '/profile',
  settings: '/settings',
  connections: '/connections',
  dashboard: '/dashboard',
  messages: '/messages',
  rooms: '/rooms',
  insights: '/insights',
  members: '/members',
  claim: '/claim'
}
