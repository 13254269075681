import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { Icon } from '@iconify/react'
import homeFill from '@iconify/icons-eva/home-fill'
import personFill from '@iconify/icons-eva/person-fill'
// import settings2Fill from '@iconify/icons-eva/settings-2-fill'
import { Link as RouterLink } from 'react-router-dom'
// material
import { alpha, styled } from '@mui/material/styles'
import { Avatar, Box, Divider, MenuItem, Typography } from '@mui/material'
// hooks
import useAuth from '../../hooks/useAuth'
import useUser from '../../hooks/useUser'
// components
import { MIconButton } from '../../components/@material-extend'
import MenuPopover from '../../components/MenuPopover'

// ----------------------------------------------------------------------

/**
 * Reasoning on keeping the commented code lines:
 * Line #6:
 * import settings2Fill from @iconify/icons-eva/settings-2-fill
 * and line #38
 * { label: MENU_LABELS.SETTINGS, icon: settings2Fill, linkTo: '/account' },
 * are not part of the current release, but they will be needed in a future release
 * so we will keep the commented code to save time when the actual implementation will be made
 */

const MENU_LABELS = {
  HOME: 'Home',
  SETTINGS: 'Admin Settings',
  LOGOUT: 'Logout'
}

const MENU_OPTIONS = [
  { label: MENU_LABELS.HOME, icon: homeFill, linkTo: '/' },
  //  { label: MENU_LABELS.SETTINGS, icon: settings2Fill, linkTo: '/account' },
  { label: MENU_LABELS.LOGOUT, icon: personFill, linkTo: '/auth/login' }
]

const IframeStyle = styled('iframe')(() => ({
  display: 'none'
}))

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [appwebLogoutUrl, setAppwebLogoutUrl] = useState(null)
  const navigate = useNavigate()

  const { googleLogout } = useAuth()
  const { data: user } = useUser()

  const { first_name: firstName, email, image_url: imageUrl } = user

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (e) => {
    e.preventDefault()
    const optionLabel = e.target.innerText.trim()
    switch (optionLabel) {
      case MENU_LABELS.HOME:
        navigate(MENU_LABELS.HOME)
        break
      case MENU_LABELS.LOGOUT:
        // It will store the logout url to a state variable
        // used by the hidden iframe to load it
        setAppwebLogoutUrl(`${process.env.REACT_APP_APPWEB_BASE_URL}/logout`)
        break
      default:
    }
    setOpen(false)
  }

  const onLogoutPageLoad = () => {
    // this will execute once the logout page is loaded in the hidden iframe
    // then we can run the local logout that will redirect current page
    // and unmount the current component (with the iframe)
    // If there is an error while loading the logout page we still run the
    // googleLogout
    googleLogout()
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.3)
            }
          })
        }}
      >
        <Avatar alt={firstName} src={imageUrl} />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
      {appwebLogoutUrl && (
        <IframeStyle
          onLoad={onLogoutPageLoad}
          onError={onLogoutPageLoad}
          style={{ display: 'none' }}
          title="logout-page"
          src={appwebLogoutUrl}
        />
      )}
    </>
  )
}
