import PropTypes from 'prop-types'
// components
import { ListFooter } from '../list'

SpacesFooter.propTypes = {
  records: PropTypes.array,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func
}

export default function SpacesFooter({
  records,
  rowsPerPage,
  setRowsPerPage,
  page,
  onPageChange,
  onChangeRowsPerPage
}) {
  return (
    <ListFooter
      records={records}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      onChangePage={onPageChange}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  )
}
