// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

export default function LinkedinIcon({ ...other }) {
  return (
    <Box {...other}>
      <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="#007EBB">
          <path d="M15.15 8.4a5.83 5.83 0 0 0-5.85 5.82v5.88a.9.9 0 0 0 .9.9h2.1a.9.9 0 0 0 .9-.9v-5.88a1.94 1.94 0 0 1 2.15-1.93 2 2 0 0 1 1.75 2v5.81a.9.9 0 0 0 .9.9h2.1a.9.9 0 0 0 .9-.9v-5.88a5.83 5.83 0 0 0-5.85-5.82z" />
          <rect x="3" y="9.3" width="4.5" height="11.7" rx="1.08" />
          <circle cx="5.25" cy="5.25" r="2.25" />
        </g>
      </svg>
    </Box>
  )
}
