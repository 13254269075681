import PropTypes from 'prop-types'
import { Typography, Stack, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { NPS_RANGE_COLORS } from '../../theme/palette'

const SpaceCardStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: `solid 1px ${theme.palette.grey[500_80]}`,
  height: '254px',
  width: '265px',
  maxWidth: '265px', // When the scrollbar is showing rows may wrap
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadiusSm
}))

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})

const GrayOutlinedButton = styled('button')(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: '#fff',
  borderRadius: 8,
  padding: '6px 12px',
  border: '1px solid',
  fontWeight: 700,
  lineHeight: 1.5,
  borderColor: theme.palette.grey[500_32],
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[500_8]
  },
  '&:active': {
    backgroundColor: theme.palette.grey[500_16]
  },
  '&:focus': {
    backgroundColor: theme.palette.grey[500_24]
  }
}))

const EmptySpace = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  boxShadow: 'none',
  backgroundColor: '#fff',
  padding: '6px 12px'
}))

const SpaceName = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: 'text.primary'
}))

/**
 * This function is only used for this component
 * It's ok for now not to export it as an external shared function
 * @param {number} NPS value
 * @returns A color code depending on value range
 */
const getNPSTextColor = (value) => {
  // Reanges can also be constants but for now it's ok to hard-code them
  if (value >= 1 && value <= 30) {
    return NPS_RANGE_COLORS.orange
  }
  if (value > 30 && value <= 70) {
    return NPS_RANGE_COLORS.yellow
  }
  if (value > 70) {
    return NPS_RANGE_COLORS.green
  }
  return NPS_RANGE_COLORS.red
}

export default function SpaceCard({ space, loadSpace, user, onOpenManager }) {
  const handleViewClick = () => loadSpace(space.id)

  const handleManageClick = () => onOpenManager(space)

  const valueStyle = { fontSize: 18, fontWeight: 'bold' } // Common style for all values

  return (
    <SpaceCardStyle>
      <SpaceName title={space.name}>{space.name}</SpaceName>

      <RowStyle>
        <Typography sx={{ color: 'text.secondary' }}>Total NPS</Typography>
        <Typography sx={{ ...valueStyle, color: getNPSTextColor(space.nps_score) }}>{space.nps_score}</Typography>
      </RowStyle>

      <RowStyle>
        <Typography sx={{ color: 'text.secondary' }}>Rooms</Typography>
        <Typography sx={valueStyle} variant="subtitle1">
          {space.rooms_count}
        </Typography>
      </RowStyle>

      <RowStyle>
        <Typography sx={{ color: 'text.secondary' }}>Members</Typography>
        <Typography sx={valueStyle} variant="subtitle1">
          {space.members_count}
        </Typography>
      </RowStyle>

      {user.isAdmin ? <GrayOutlinedButton onClick={handleManageClick}>Manage</GrayOutlinedButton> : <EmptySpace />}

      <Stack>
        <Button variant="outlined" onClick={handleViewClick}>
          View
        </Button>
      </Stack>
    </SpaceCardStyle>
  )
}

SpaceCard.propTypes = {
  space: PropTypes.object,
  loadSpace: PropTypes.func,
  user: PropTypes.object,
  onOpenManager: PropTypes.func
}
