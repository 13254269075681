import api from './api'
import assetAPI from './assetAPI'

// get
export const getRoomNotifies = (userId) => api.get(`users/${userId}/rooms/notifies`)
export const getRoomById = (roomId) => api.get(`rooms/${roomId}`)
export const getRoomAnalytics = (roomId) => api.get(`/analytics/rooms/${roomId}`)
export const getActives = () => api.get(`rooms/active`)

// put
export const deleteSpaceRoom = (roomId, roomInfo) => api.put(`rooms/${roomId}`, roomInfo)
export const updateRoom = (roomId, roomData) => api.put(`rooms/${roomId}`, roomData)

// post
export const addSpaceRoom = (payload) => api.post('/rooms', payload)

// ASSET API
export const addSpaceRoomVideo = (payload, roomId) => assetAPI.post(`/rooms/${roomId}/videos`, payload)
