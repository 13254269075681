import PropTypes from 'prop-types'
import { TablePagination } from '@mui/material'

// ----------------------------------------------------------------------

ListFooter.propTypes = {
  records: PropTypes.array,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func
}

export default function ListFooter({ records, rowsPerPage, page, onChangePage, onChangeRowsPerPage }) {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={records.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
    />
  )
}
