/* eslint sort-keys: "error" */
import { encryptData, decryptData } from './cryptData'

export const KEYS = {
  CLAIM_FLOW: 'claim-flow',
  EMAIL: 'email',
  REFRESH_TOKEN: 'refresh-token',
  SPACE_ID: 'space-id',
  TOKEN: 'token',
  USER_ID: 'user-id'
}

export const getValue = (key) => decryptData(localStorage.getItem(key) || '')

export const saveValue = (key, userId) => localStorage.setItem(key, encryptData(userId))

export const removeValue = (key) => localStorage.removeItem(key)
