import PropTypes from 'prop-types'
// material
import { styled } from '@mui/material/styles'
import { Stack, Divider, Button, Typography, Alert, Box, Switch, OutlinedInput } from '@mui/material'
import { useTheme } from '@mui/styles'
// components
import { DialogAnimate } from '../animate'

CreateSpace.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  toggleScreenShare: PropTypes.bool,
  onToggle: PropTypes.func,
  spaceName: PropTypes.any,
  setSpaceName: PropTypes.func
}

const HeaderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1)
}))

const TitleStyle = styled('span')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 'bold'
}))

const SubtitleStyle = styled('span')(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: theme.typography.pxToRem(14),
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightRegular
}))

const Header = () => (
  <HeaderStyle>
    <TitleStyle>New space</TitleStyle>
    <SubtitleStyle>Create a space for your company, separate teams or events.</SubtitleStyle>
  </HeaderStyle>
)

const MAX_LENGTH = 256

export default function CreateSpace({ open, onClose, onSave, toggleScreenShare, onToggle, spaceName, setSpaceName }) {
  const theme = useTheme()

  const CustomButton = styled(Button)`
    &.Mui-disabled {
      background-color: ${theme.palette.buttons.defultBackground};
      color: ${theme.palette.buttons.defaultText};
      opacity: 0.5;
    }
  `

  const handleClose = () => {
    onClose()
  }
  const handleChange = (e) => {
    setSpaceName(e.target.value)
  }

  const handleSave = () => {
    onSave({ name: spaceName })
  }
  return (
    <DialogAnimate open={open} onClose={onClose} fullWidth maxWidth="md">
      <Stack>
        <Stack display="flex" flexDirection="row">
          <Stack width="50%">
            <Stack pl={2} mt={2}>
              <Header />
            </Stack>
            <Stack mt={1} mb={9} ml={1} width={430} p={2}>
              <OutlinedInput
                sx={{ width: 400, fontWeight: 600 }}
                value={spaceName}
                placeholder="Give your space a name..."
                onChange={handleChange}
              />
              <Stack>
                <Stack mt={6} direction="row" alignItems="center" spacing={9}>
                  <Typography
                    sx={
                      toggleScreenShare
                        ? { opacity: 0.5, color: theme.palette.grey[800] }
                        : { color: theme.palette.grey[800] }
                    }
                    variant="subtitle1"
                  >
                    Allow guests to share their screen
                  </Typography>
                  <Switch checked={!toggleScreenShare} onChange={onToggle} />
                </Stack>
                <Typography
                  sx={
                    toggleScreenShare
                      ? { opacity: 0.5, color: theme.palette.grey[800] }
                      : { color: theme.palette.grey[800] }
                  }
                  variant="body2"
                >
                  Applies to all rooms in this space.
                </Typography>
              </Stack>
            </Stack>
            {spaceName.length > MAX_LENGTH && (
              <Box width={379} sx={{ position: 'absolute', top: 200 }}>
                <Alert severity="error">'Name length must be less than or equal to 256 characters long'</Alert>
              </Box>
            )}
          </Stack>

          <Stack
            width="50%"
            style={{
              flex: 1,
              fontSize: '16px',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#f2efff'
            }}
          >
            <Typography style={{ width: '268px', fontWeight: '600' }}>
              Twine guides conversations in dedicated rooms. These rooms belong to specific spaces. Guests invited to a
              room will belong to that space.
            </Typography>
          </Stack>
        </Stack>

        <Stack>
          <Divider />
          <Stack pt={2} px={3} pb={2} direction="row" justifyContent="space-between">
            <Button
              sx={{
                color: theme.palette.grey[800],
                border: theme.palette.buttons.doneBorder
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <CustomButton
              onClick={handleSave}
              variant="contained"
              disabled={!spaceName?.length || spaceName.length > MAX_LENGTH}
              sx={{ color: theme.palette.secondary, border: theme.palette.buttons.doneBorder, boxShadow: 'none' }}
            >
              Create
            </CustomButton>
          </Stack>
        </Stack>
      </Stack>
    </DialogAnimate>
  )
}
