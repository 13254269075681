// utils
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
// routes
import Router from './routes'
// theme
import ThemeConfig from './theme'
import GlobalStyles from './theme/globalStyles'
// components
import NotistackProvider from './components/NotistackProvider'
import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/LoadingScreen'
import ThemePrimaryColor from './components/ThemePrimaryColor'

// ----------------------------------------------------------------------

const { REACT_APP_SENTRY_URL, REACT_APP_SENTRY_SAMPLE_RATE, REACT_APP_SENTRY_ENV } = process.env

Sentry.init({
  dsn: REACT_APP_SENTRY_URL,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // set to lower in production
  tracesSampleRate: parseFloat(REACT_APP_SENTRY_SAMPLE_RATE),
  environment: REACT_APP_SENTRY_ENV
})

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          <GlobalStyles />
          <ProgressBarStyle />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  )
}
