import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// material
import { Stack, Divider, Button, Typography, Switch, OutlinedInput } from '@mui/material'
import { useTheme } from '@mui/styles'
import { DeleteOutlined } from '@mui/icons-material'
import { styled } from '@mui/system'
// components
import { DialogAnimate } from '../animate'
import SpaceConfirmationDialog from './SpaceConfirmationDialog'

SpaceManager.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  space: PropTypes.object,
  onSave: PropTypes.func,
  toggleScreenShare: PropTypes.bool,
  onToggle: PropTypes.func,
  isShareScreen: PropTypes.bool,
  onDisable: PropTypes.func
}

export default function SpaceManager({
  open,
  onClose,
  space,
  onSave,
  toggleScreenShare,
  onToggle,
  onDisable,
  isShareScreen
}) {
  const [spaceName, setSpaceName] = useState('')
  const [isOpen, setOpen] = useState()

  const theme = useTheme()
  const CustomButton = styled(Button)`
    &.Mui-disabled {
      background-color: ${theme.palette.buttons.defultBackground};
      color: ${theme.palette.buttons.defaultText};
      opacity: 0.5;
    }
  `

  useEffect(() => {
    setSpaceName(space ? space.name : '')
  }, [space])

  const handleClose = () => onClose()

  const handleChange = (e) => {
    setSpaceName(e.target.value)
  }
  const handleDeleteSpace = () => {
    setOpen(true)
  }
  const handleSave = () => onSave({ id: space.id, name: spaceName })

  const deleteHandleClose = () => {
    setOpen(false)
  }

  return (
    <DialogAnimate open={open} onClose={onClose} fullWidth maxWidth="md">
      <Stack spacing={3} p={3}>
        <Typography my="6px" sx={{ fontSize: 18, fontWeight: 'bold' }}>
          Manage this space
        </Typography>
        <Stack spacing={3} justifyContent="flex-start" alignItems="start" direction="row">
          <OutlinedInput
            sx={{ width: 400, fontWeight: 600 }}
            value={spaceName}
            placeholder="Name"
            onChange={handleChange}
          />
          <Button
            sx={{
              color: theme.palette.buttons.destroyBackground,
              border: `1px solid ${theme.palette.buttons.destroyBackground}`
            }}
            onClick={handleDeleteSpace}
            variant="outlined"
          >
            <DeleteOutlined sx={{ mr: 1 }} color="error" />
            Delete space
          </Button>
        </Stack>
        <SpaceConfirmationDialog
          isOpen={isOpen}
          onClose={deleteHandleClose}
          onDeleteSpace={onDisable}
          spaceName={space.name}
        />
        <Stack>
          <Stack direction="row" alignItems="center" spacing={9}>
            <Typography variant="subtitle1">Allow guests to share their screen</Typography>
            <Switch checked={!toggleScreenShare} onChange={onToggle} />
          </Stack>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Applies to all rooms in this space.
          </Typography>
        </Stack>
        <Stack>
          <Divider />
          <Stack pt="16px" direction="row" justifyContent="space-between">
            <Button
              sx={{
                color: theme.palette.secondary,
                border: theme.palette.buttons.doneBorder
              }}
              variant="inherit"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <CustomButton
              sx={{
                color: theme.palette.secondary,
                border: theme.palette.buttons.doneBorder,
                boxShadow: 'none'
              }}
              onClick={handleSave}
              variant="contained"
              disabled={toggleScreenShare === isShareScreen && (!spaceName?.length || space.name === spaceName)}
            >
              Save
            </CustomButton>
          </Stack>
        </Stack>
      </Stack>
    </DialogAnimate>
  )
}
