import { createSlice } from '@reduxjs/toolkit'
// services
import {
  deleteBackgroundPicture,
  getUserById,
  updateUserInfo,
  uploadBackgroundPicture,
  uploadProfilePicture
} from '../../services/user'
// redux
import { getSpace, reset as resetSpace } from './space'
import { getRooms, reset as resetRooms, getActiveRooms } from './rooms'
import { getMembers, reset as resetMembers } from './members'
import { getConnections, reset as resetConnections } from './connections'

// utils
import { log } from '../../utils/logger'
import { getValue, KEYS } from '../../utils/handleLocalStorage'
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {}
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getUserSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      const user = action.payload
      const newData = { ...user, isAdmin: !!user.spaces?.find((space) => user?.admin_space_ids.includes(space.id)) }
      state.data = newData
    },

    reset(state) {
      state.data = {}
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getUser(userId) {
  const { startLoading, hasError, getUserSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getUserById(userId)
      const user = data.response_objects
      dispatch(getUserSuccess(user))
      dispatch(getConnections(userId))
      dispatch(getActiveRooms())
      const spaceIds = user.space_ids
      const storedSpaceId = parseInt(getValue(KEYS.SPACE_ID), 10)
      const spaceId = spaceIds.includes(storedSpaceId) ? storedSpaceId : spaceIds[spaceIds.length - 1] // Get latest space id or stored one if user already stored it
      // Don't load a space without a spaceId. New users may not have a space
      if (spaceId) {
        dispatch(getSpace(spaceId))
        dispatch(getMembers(spaceId))
        dispatch(getRooms(spaceId))
      }
    } catch (error) {
      log.error({ userId, error })
      dispatch(hasError(error))
    }
  }
}

/**
 * Updates a User information.
 * ProfilePicture is optional and should be a File object mostly returned by an `input` tag.
 * @param {User} user user object with the data to be updated
 * @param {File?} profilePicture a File object that represents the new picture to upload. It can be null
 * @param {File?} backgroundPicture a File object that represents the new cover picture to upload. It can be null
 * @returns an async task that last all the upload/update process
 */
export function updateUser(user, profilePicture, backgroundPicture) {
  const { hasError, getUserSuccess } = slice.actions
  let step = null
  return async (dispatch) => {
    try {
      const updatedUserInfo = {
        first_name: user.firstName,
        last_name: user.lastName,
        bio: user.bio,
        linkedin_vanity_name: user.linkedInUrl,
        linkedin_url: user.linkedInUrl,
        title: user.title,
        pronouns: user.pronouns,
        team: user.team,
        country: user.country,
        city: user.city
      }
      step = 'Sending user info to endpoint'
      await updateUserInfo(user.id, updatedUserInfo)
      // Check if we have to update the profile picture too
      if (profilePicture instanceof File) {
        step = 'Uploading profile picture'
        const picData = new FormData()
        picData.set('image', profilePicture)
        await uploadProfilePicture(user.id, picData)
      }
      if (backgroundPicture instanceof File) {
        step = 'Uploading cover picture'
        const coverPicData = new FormData()
        coverPicData.set('image', backgroundPicture)
        await uploadBackgroundPicture(user.id, coverPicData)
      } else if (backgroundPicture === 'delete') {
        step = 'Deleting cover picture'
        await deleteBackgroundPicture(user.id)
      }
      dispatch(getUser(user.id))
      const data = await getUserById(user.id)
      const updatedUser = data.response_objects
      dispatch(getUserSuccess(updatedUser))
    } catch (error) {
      log.error({ step, user, profilePicture, error })
      dispatch(hasError(error))
    } finally {
      dispatch(getUser(user.id))
    }
  }
}

export function refreshUserData(userId) {
  const { hasError, getUserSuccess } = slice.actions
  return async (dispatch) => {
    try {
      const data = await getUserById(userId)
      const user = data.response_objects
      dispatch(getUserSuccess(user))
    } catch (error) {
      log.error({ userId, error })
      dispatch(hasError(error))
    }
  }
}

export function reset() {
  const { reset } = slice.actions
  return async (dispatch) => {
    dispatch(reset())
  }
}

export function resetData() {
  const { reset } = slice.actions
  return async (dispatch) => {
    dispatch(reset())
    dispatch(resetSpace())
    dispatch(resetRooms())
    dispatch(resetMembers())
    dispatch(resetConnections())
  }
}
