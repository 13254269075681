import PropTypes from 'prop-types'
import { Dialog, DialogContent, Button, Typography, Divider, Stack } from '@mui/material'
import { useTheme } from '@mui/styles'

SpaceConfirmationDialog.propTypes = {
  onDeleteSpace: PropTypes.any,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  spaceName: PropTypes.string
}

export default function SpaceConfirmationDialog({ isOpen, onClose, onDeleteSpace, spaceName }) {
  const theme = useTheme()

  const handleDeleteSpace = () => {
    onClose()
    onDeleteSpace()
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Stack width={383} spacing={2} justifyContent="space-between">
        <Typography
          sx={{ color: theme.palette.grey[800], fontWeight: 'bold', fontSize: 20, textAlign: 'center', mt: 4, px: 2 }}
        >
          Are you sure you want to delete this space?
        </Typography>
        <DialogContent>
          <Typography sx={{ color: theme.palette.grey[800], fontWeight: '500', fontSize: 18 }} textAlign="center">
            {`Everything in “${spaceName}” will be deleted forever.`}
          </Typography>
        </DialogContent>
        <Divider />
      </Stack>
      <Stack p={2} direction="row" justifyContent="space-between">
        <Button variant="contained" color="inherit" onClick={onClose} sx={{ boxShadow: 'none' }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.buttons.defultBackground,
            boxShadow: 'none'
          }}
          onClick={handleDeleteSpace}
        >
          Yes
        </Button>
      </Stack>
    </Dialog>
  )
}
